import { AuthApi } from "../client/client";
import { User } from "../types/User";
import { Alert } from "../types/Alert";
import { Message } from "../types/Message";
import { generateAlert } from "../utils/generateAlert";
import { AlertType } from "../types/Alert";

const route = "/alerts"

class AlertsService {
    async createAlerts (type: AlertType, carId: number) {
        await AuthApi.post<Message>(`${route}/create-alert`, generateAlert(type, carId))
    };
    
    async getAlerts ({ id }: Pick<User, 'id'>): Promise<Alert[]> {
        const { data } = await AuthApi.get<Alert[]>(`${route}/get-alerts?id=${id}`);
    
        return data;
    }
    
    async getAlertsQunatity  ({ id }: Pick<User, 'id'>): Promise<{quantity: number}> {
        const { data } = await AuthApi.get<{quantity: number}>(`${route}/get-alerts-quantity?id=${id}`);
    
        return data;
    }
    
    async removeAlert (id: Pick<Alert, 'id'>): Promise<Message> {
        const { data } = await AuthApi.post<Message>(`${route}/remove-alert`, id);
    
        return data;
    }
    
    async removeAlerts (payload: { alertRecipientId: number }): Promise<Message> {
        const { data } = await AuthApi.post<Message>(`${route}/remove-alerts`, payload);
    
        return data;
    }    
}

export default new AlertsService();