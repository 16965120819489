import axios from "axios";
import localStorageService from "../services/localStorageService";
import { constants } from '../constants/constants'

const serverUrl = constants.BASE_URL;

const Api = axios.create({
    baseURL: `${serverUrl}`
});

const AuthApi = axios.create({
    baseURL: `${serverUrl}`,
    headers: {
        Authorization: 'Bearer ' + localStorageService.getToken()
    }
});

export {
    Api, AuthApi, serverUrl
}