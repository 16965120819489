import ControlsIcon from '../../../../../images/controls.svg'

const ControlsLink = () => {
  return (
    <a href="/controls">
        <img src={ControlsIcon} alt="Админ панель" width={30} height={30} />
    </a>
  )
}

export default ControlsLink