import styles from './scrollButton.module.scss';

const ScrollButton = () => {
  return(
    <a href="#top" className={styles.scrollButton}>
      <div>
        <span></span>
      </div>
    </a>
  )
}

export default ScrollButton;