import { create } from 'zustand'
import { User } from '../types/User'

interface UserStore {
  user: User | null
  isLoading: boolean
  setUser: (user: User | null, isLoading: boolean) => void
}

export const userStore = create<UserStore>()((set) => ({
    user: null,
    isLoading: false,
    setUser: (user: User | null, isLoading: boolean) => set(() => ({user, isLoading}))
}));