import AlertsIcon from '../../../../../images/alerts.svg'
import styles from './alertsLink.module.scss';

interface IAlertsLink {
  alertsNumber: number
}

const AlertsLink = ({ alertsNumber }: IAlertsLink) => {
  return (
    <a href="/alerts" id="alertsNumber" className={styles.alertsLink} data-quantity={alertsNumber}>
      <img src={AlertsIcon} alt="Оповещения" width={30} height={30}/>
    </a>
  )
}

export default AlertsLink