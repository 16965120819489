import { memo, useState, useEffect } from 'react'
import AlertsLink from './ui/AlertsLink/AlertsLink';
import ProfileLink from './ui/ProfileLink/ProfileLink';
import ControlsLink from './ui/ControlsLink/ControlsLink';
import Logo from '../../../images/logo.svg';
import { userStore } from '../../../zustand/userStore';
import styles from './header.module.scss';
import { roleCheck } from '../../../utils/roleCheck';
import alertsService from '../../../services/alertsService';

const Header = memo(() => {
    const { user } = userStore();

    const [alertsNumber, setAlertsNumber] = useState(0);

    const isRootRole = user && roleCheck(['admin', 'rop', 'content manager'], user.role);

    useEffect(() => {
        if(user && isRootRole){
            alertsService.getAlertsQunatity({ id: user.id })
            .then((data) => setAlertsNumber(data.quantity))
        }
    }, [user]);

  return (
    <header className={styles.header} id="top">
        <div className="container">
            <div className={styles.headerInner}>
                <div>
                    <a href="/">
                        <img src={Logo} alt="Альянс Тракс" width={125} height={50}/>
                    </a>
                </div>
                <div className={styles.headerRight}>
                    {
                    user
                    &&
                    <>
                        {
                        isRootRole
                        &&
                        <>
                            <AlertsLink alertsNumber={alertsNumber}/>
                            <ControlsLink />
                        </>
                        }
                        <ProfileLink role={user.role} firstName={user.firstName} lastName={user.lastName} patronymic={user.patronymic}/>
                    </>
                    }
                </div>
            </div>
        </div>
    </header>
  )
})

export default Header