import { useState, useEffect } from "react"
import Header from "./ui/Header/Header"
import ScrollButton from "./ui/ScrollButton/ScrollButton"
import styles from './layout.module.scss'

const Layout = ({ children }: { children: React.ReactNode }) => {

  const [showButton, setShowButton] = useState(false);

  const watchScroll = () => {
    if(window.scrollY > 200){
      setShowButton(true)
    }else{
      setShowButton(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', watchScroll)

    return () => {
      window.removeEventListener('scroll', watchScroll)
    }
  }, [])

  return (
    <>
    <Header />
    <div className={styles.layoutWrapper}>
        <div className="container">
          { children }
        </div>
    </div>
    {showButton && <ScrollButton/>}
    </>
  )
}

export default Layout