import styles from './profileLink.module.scss';
import { User } from '../../../../../types/User';
import UserIcon from '../../../../../components/UserIcon/UserIcon';
import { roles } from '../../../../../data/roles';

const ProfileLink = ({ role, firstName, lastName, patronymic}: Pick<User, 'firstName' | 'lastName' | 'patronymic' | 'role'>) => {
  return (
    <a href="/profile" className={styles.profileLink}>
        <UserIcon letter={firstName[0]}/>
        <div className={styles.profileLinkUserInfo}>
            <div>
                <div>{roles[role]}</div>
            </div>
            <div>
                {`${lastName} ${firstName[0]} ${patronymic[0]}`}
            </div>
        </div>
    </a>
  )
}

export default ProfileLink