import { roles } from "../data/roles";
import { Role } from "../types/Role";
import { userStore } from "../zustand/userStore";
import { AlertType } from "../types/Alert";

export const generateAlert = (type: AlertType, carId: number) => {
    const { user } = userStore.getState();
    const alertAuthorName = `${user!.lastName} ${user!.firstName} ${user!.patronymic}`;

    return {
        authorId: user!.id,
        role: (roles[user!.role] as Role),
        alertAuthorName,
        carId,
        type
    }
};