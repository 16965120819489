const PageLoader = () => {
  return (
    <div className="loadingio-spinner-double-ring-kajbsg5m19">
    <div className="ldio-ceax5cfvi78">
        <div></div>
        <div></div>
        <div>
            <div>
            </div>
        </div>
        <div>
            <div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default PageLoader