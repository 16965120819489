import PageLoader from './ui/PageLoader/PageLoader'
import styles from './loader.module.scss'

const Loader = () => {
  return (
    <div className={styles.loader}>
        <PageLoader />
    </div>
  )
}

export default Loader