import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Loader from './components/Loader/Loader';
import Layout from './Layout/Layout';
import { userStore } from './zustand/userStore';
import localStorageService from './services/localStorageService';
import authService from './services/authService';
import { Role } from './types/Role';
import './styles/globals.scss';

const TruckCreate = lazy(() => import("./carPages/Truck/TruckCreate/TruckCreate"));
const LCVCreate = lazy(() => import("./carPages/LCV/LCVCreate/LCVCreate"));
const CABCreate = lazy(() => import("./carPages/CAB/CABCreate/CABCreate"));
const ConstructionCreate = lazy(() => import("./carPages/Construction/ConstructionCreate/ConstructionCreate"));
const MunicipalCreate = lazy(() => import("./carPages/Municipal/MunicipalCreate/MunicipalCreate"));
const LoaderCreate = lazy(() => import("./carPages/Loader/LoaderCreate/LoaderCreate"));
const DredgeCreate = lazy(() => import("./carPages/Dredge/DredgeCreate/DredgeCreate"));
const AutocraneCreate = lazy(() => import("./carPages/Autocrane/AutocraneCreate/AutocraneCreate"));

const TruckEdit = lazy(() => import("./carPages/Truck/TruckEdit/TruckEdit"));
const LCVEdit = lazy(() => import("./carPages/LCV/LCVEdit/LCVEdit"));
const CABEdit = lazy(() => import("./carPages/CAB/CABEdit/CABEdit"));
const ConstructionEdit = lazy(() => import("./carPages/Construction/ConstructionEdit/ConstructionEdit"));
const MunicipalEdit = lazy(() => import("./carPages/Municipal/MunicipalEdit/MunicipalEdit"));
const LoaderEdit = lazy(() => import("./carPages/Loader/LoaderEdit/LoaderEdit"));
const DredgeEdit = lazy(() => import("./carPages/Dredge/DredgeEdit/DredgeEdit"));
const AutocraneEdit = lazy(() => import("./carPages/Autocrane/AutocraneEdit/AutocraneEdit"));

const TruckMainEdit = lazy(() => import("./carPages/Truck/TruckMainEdit/TruckMainEdit"));
const LCVMainEdit = lazy(() => import("./carPages/LCV/LCVMainEdit/LCVMainEdit"));
const CABMainEdit = lazy(() => import("./carPages/CAB/CABMainEdit/CABMainEdit"));
const ConstructionMainEdit = lazy(() => import("./carPages/Construction/ConstructionMainEdit/ConstructionMainEdit"));
const MunicipalMainEdit = lazy(() => import("./carPages/Municipal/MunicipalMainEdit/MunicipalMainEdit"));
const LoaderMainEdit = lazy(() => import("./carPages/Loader/LoaderMainEdit/LoaderMainEdit"));
const DredgeMainEdit = lazy(() => import("./carPages/Dredge/DredgeMainEdit/DredgeMainEdit"));
const AutocraneMainEdit = lazy(() => import("./carPages/Autocrane/AutocraneMainEdit/AutocraneMainEdit"));

const Homepage = lazy(() => import("./pages/Homepage/Homepage"));
const Auth = lazy(() => import("./pages/Auth/Auth"));
const Profile = lazy(() => import("./pages/Profile/Profile"));
const Controls = lazy(() => import("./pages/Controls/Controls"));
const Waiting = lazy(() => import("./pages/Waiting/Waiting"));
const Alerts = lazy(() => import("./pages/Alerts/Alerts"));
const Car = lazy(() => import("./pages/Car/Car"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage/NotFoundPage"));
const AccountCreate = lazy(() => import("./pages/AccountCreate/AccountCreate"));

const getRouteElement = (component: React.ReactNode) => {
  return(
    <Suspense fallback={<Loader />}>
      <Layout>
        { component }
      </Layout>
    </Suspense>
  );
};

const getCarRouteElement = (role: Role, namespace: string, components: React.ReactNode[]) => { 
  if(role !== 'partner' && role !== 'manager'){
    return [
      {
        path: `/${namespace}/create/:type`,
        element: getRouteElement(components[0]),
      },
      {
        path: `/${namespace}/edit`,
        element: getRouteElement(components[1]),
      },
      {
        path: `/${namespace}/edit-main`,
        element: getRouteElement(components[2]),
      }
    ]
  }else{
    return []
  };
};

const getRouter = (role: Role) => {

  const isRootRole = role !== 'partner' && role !== 'manager';

  return createBrowserRouter([
    {
      path: "*",
      element: getRouteElement(<NotFoundPage />)
    },
    {
      path: "/",
      element: getRouteElement(<Homepage />)
    },
    {
      path: "/authorization",
      element: getRouteElement(<Auth />),
    },
    {
      path: "/profile",
      element: getRouteElement(<Profile />),
    },
    {
      path: "/controls",
      element: getRouteElement(isRootRole ? <Controls /> : <NotFoundPage />),
    },
    {
      path: "/waiting",
      element: getRouteElement(<Waiting />),
    },
    {
      path: "/alerts",
      element: getRouteElement(isRootRole ? <Alerts /> : <NotFoundPage />),
    },
    {
      path: "/accounts/create",
      element: getRouteElement(isRootRole ? <AccountCreate /> : <NotFoundPage />),
    },
    {
      path: "/car",
      element: getRouteElement(<Car />),
    },
    ...getCarRouteElement(role, 'truck', [
      <TruckCreate />,
      <TruckEdit />,
      <TruckMainEdit />
    ]),
    ...getCarRouteElement(role, 'lcv', [
      <LCVCreate />,
      <LCVEdit />,
      <LCVMainEdit />
    ]),
    ...getCarRouteElement(role, 'cab', [
      <CABCreate />,
      <CABEdit />,
      <CABMainEdit />
    ]),
    ...getCarRouteElement(role, 'construction', [
      <ConstructionCreate />,
      <ConstructionEdit />,
      <ConstructionMainEdit />
    ]),
    ...getCarRouteElement(role, 'municipal', [
      <MunicipalCreate />,
      <MunicipalEdit />,
      <MunicipalMainEdit />
    ]),
    ...getCarRouteElement(role, 'loader', [
      <LoaderCreate />,
      <LoaderEdit />,
      <LoaderMainEdit />
    ]),
    ...getCarRouteElement(role, 'dredge', [
      <DredgeCreate />,
      <DredgeEdit />,
      <DredgeMainEdit />
    ]),
    ...getCarRouteElement(role, 'autocrane', [
      <AutocraneCreate />,
      <AutocraneEdit />,
      <AutocraneMainEdit />
    ])
  ]);
}

const { setUser } = userStore.getState()

const token = localStorageService.getToken();

if(token){
  authService.getMe()
  .then((data) => {
    setUser(data, false);
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <>
        {
        !data.isVerificated
        &&
        <Waiting />
        }
        {
        data.isVerificated
        &&
        <React.StrictMode>
          <RouterProvider router={getRouter(data.role)}/>
        </React.StrictMode>
        }
      </>,
    )
  })
  .catch(() => {
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <Auth />
      </React.StrictMode>
    )
  })
}else{
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <Auth />
    </React.StrictMode>
  )
}
