const namespaces = {
    lastLocation: 'alliance-trucks-location',
    tokenName: 'alliance-trucks-access-token'
};

class localStorageService {
    getLastLocation() {
        return localStorage.getItem(namespaces.lastLocation)
    }

    setLastLocation(location: string) {
        return localStorage.setItem(namespaces.lastLocation, location)
    }

    getToken() {
        return localStorage.getItem(namespaces.tokenName)
    }

    setToken(token: string) {
        return localStorage.setItem(namespaces.tokenName, token)
    }

    removeToken() {
        return localStorage.removeItem(namespaces.tokenName)
    }
};

export default new localStorageService();