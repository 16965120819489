// const constants = {
//     CLIENT_URL: 'http://localhost:3000',
//     SERVER_URL: 'http://localhost:5000',
//     BASE_URL: 'http://localhost:5000/api',
//     BASE_STATIC_URL: 'http://localhost:5000/static',
//     ACCESS_TOKEN: 'Alliance-Trucks-Access-Token'
// }

const constants = {
    CLIENT_URL: 'https://stock-alliance.ru',
    SERVER_URL: 'https://api.stock-alliance.ru',
    BASE_URL: 'https://api.stock-alliance.ru/api',
    BASE_STATIC_URL: 'https://api.stock-alliance.ru/static',
    ACCESS_TOKEN: 'Alliance-Trucks-Access-Token'
}
    
const routes = {
    ARCHIVES_ROUTE: '/archives',
    ORIGINAL_IMAGES_ROUTE: '/cars',
    OPTIMIZED_IMAGES_ROUTE: '/optimized',
    DOCUMENTS_ROUTE: '/docs',
    XMLS_ROUTE: '/xmls',
    VIDEOS_ROUTE: '/video',
}

export { constants, routes }