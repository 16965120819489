import { AuthApi } from "../client/client";
import { User } from "../types/User";

class AuthService {
    async getMe (): Promise<User> {
        const { data } = await AuthApi.get<User>('/user/get-me');

        return data
    }
}

export default new AuthService();